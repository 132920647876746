///include /assets/js/app/p/s-basket-service.js
"use strict"

class SBProductPricing {
    /**
     *
     * @param {SProduct["pricing"][0]} [p]
     * @param {boolean} [isNew]
     * @param {?(this: SBProductPricing) => ?number} [get_implied_price]
     */
    constructor(
        p = {action: null, currency: null, isManaged: false, periodMonths: null, paymentType: "any", price: null},
        isNew = true,
        get_implied_price = null
    ) {
        this.action = p.action
        this.currency = p.currency
        this.getImpliedPrice = get_implied_price
        this.periodMonths = p.periodMonths
        this.isManaged = p.isManaged
        this.managedMarkup = +p.managedMarkup
        this.managedMarkupType = p.managedMarkupType
        this.managedMarkedUpPrice = p.managedMarkedUpPrice
        this.isNew = isNew

        /** @type {generalPaymentType} */
        //@ts-ignore
        this.paymentType = p.paymentType

        this.price = p.price
    }

    get periodMonths() {
        return this._periodMonths
    }
    set periodMonths(v) {
        if(this.price && v && this._periodMonths) {
            this.price = Math.round(
                this.price * v * 100 / this._periodMonths
            ) / 100
        }
        this._periodMonths = v
    }

    set price(v) {
        this._price = v === null ? v : +v
    }
    /**
     * @type {?number}
     */
    get price() {
        if(this._price !== null) {
            return this._price
        }
        if(this.getImpliedPrice) {
            const price = this.getImpliedPrice()
            if(price !== null) {
                this._price = price
                this.getImpliedPrice = null
            }
            return this._price
        }
        return null
    }
    /**
     *
     * @param {SPaymentItem["type"]} t
     * @returns {boolean}
     */
    matchesPaymentType(t) {
        if(t == this.paymentType) {
            return true
        }
        //@ts-ignore
        const real_types = SBasketService.realTypes.get(this.paymentType)
        return !!real_types && real_types.includes(t)
    }
    /**
     * The minimum price this would use to match at least m months
     *
     * @param {number} m
     * @returns {?number}
     */
    priceForMonths(m) {
        if(!this.price) return this.price
        if(m <= this.periodMonths) {
            return this.price
        } else if(!this.periodMonths) {
            return null
        } else {
            return Math.round(
                this.price * 100 * Math.ceil(m / this.periodMonths)
            ) / 100
        }
    }
    /**
     * @returns {SProduct["pricing"][0]}
     */
    toJSON() {
        return {
            action: this.action,
            currency: this.currency,
            isManaged: this.isManaged,
            managedMarkup: this.managedMarkup,
            managedMarkupType: this.managedMarkupType,
            managedMarkedUpPrice: this.managedMarkedUpPrice,
            periodMonths: this.periodMonths,
            paymentType: this.paymentType,
            price: this.price,
        }
    }
}

class SBProduct {
    static get classes() {
        if(!this._classes) {
            /**
             * @type {{[prefix: string]: typeof SBGeneralProduct}}
             */
            this._classes = {}
        }
        return this._classes
    }
    /**
     *
     * @param {SProduct} p
     */
    static async create(p) {
        const prefix = p.code.replace(/:.*/, "")
        if(!prefix.match(/^[a-z0-9_-]*$/)) {
            throw new Error(`Invalid type ${prefix}`)
        }
        try {
            await Site.require_once(`/assets/js/app/p/sbproduct/${prefix}.js`)
        } catch(e) {
            console.log(e)
        }
        if(this.classes[prefix]) {
            return new this.classes[prefix](p)
        } else {
            return new SBGeneralProduct(p)
        }
    }
    /**
     *
     * @param {(typeof SBGeneralProduct)[]} classes
     */
    static register(classes) {
        classes.forEach(
            c => this.classes[c.prefix] = c
        )
    }

    /**
     *
     * @param {SBGeneralProduct} from
     * @param {SBGeneralProduct} to
     * @param {?SPaymentItem["type"]} real_payment_type
     * @param {Date} expiry_date
     * @returns {{generalPaymentType: generalPaymentType, price: number}} Precise to 0.5
     */
    static upgradePricing(from, to, real_payment_type, expiry_date) {
        /** @type {generalPaymentType} */
        let general_payment_type
        if(real_payment_type) {
            general_payment_type = SBasketService.generalTypesFor(real_payment_type).find(
                t =>
                    from.pricing.some(p => p.paymentType == t) &&
                    to.pricing.some(p => p.paymentType == t)
            )
        } else {
            general_payment_type = from.pricing.map(p => p.paymentType).find(
                pt => to.pricing.some(p => p.paymentType == pt)
            )
        }
        const to_price = to.expectedPriceExcludingVATForDate(
            general_payment_type,
            expiry_date
        )
        const from_price = from.expectedPriceExcludingVATForDate(
            general_payment_type,
            expiry_date
        )
        const sum = to_price - from_price
        return {
            generalPaymentType: general_payment_type,
            price: sum > 0 ? Math.round(sum * 2) / 2 : 0,
        }
    }
}

/**
 * You should not need to extend this directly.
 * If its a non domain product then you likely want to extend
 * SBNonTransferProduct
 */
class SBGeneralProduct extends NetworkPropertySet {
    /** @type {string} */
    static get prefix() {
        throw new Error("Not implemented")
    }
    /**
     *
     * @param {?SProduct} [p]
     */
    constructor(p = null) {
        super()
        if(!p) {
            p = this.defaultContent
            this.isNew = true
        } else {
            this.isNew = false
        }

        this.location = null
        this.code = p.code

        // Split package allowance parts down another level
        var parts = this.code.split(":");
        if( parts[0] == 'stack_user_package_allowance' ){
            this.allowance = parts[1].split("-")[1];
            this.location  = parts[1].split("-")[2] || "uk";
        } else {
            this.allowance = null;
        }

        this.discontinued = p.discontinued
        this.hidden = p.hidden
        this.emailTemplate = p.emailTemplate
        this.affiliateCommission = p.affiliateCommission
        this.forUser      = p.forUser || null
        this._label       = p.label
        this.originalLabel = p.label
        this._pricing = null
        this.pricing = p.pricing.map(
            p => p instanceof SBProductPricing ?
                p :
                new SBProductPricing(p, false)
        )
        /**
         * @type {boolean} true if something is changing at the front or back
         * end
         */
        this.updating  = false
        this.taxExempt = !!p.taxExempt;
    }

    /** @type {SProduct} */
    get defaultContent() {
        return {
            code: this.codePrefix + ":",
            discontinued: false,
            hidden: false,
            label: null,
            pricing: this.defaultPricing,
            emailTemplate: null
        }
    }

    /** @type {SBProductPricing[]} */
    get defaultPricing() {
        throw new Error("Not implemented")
    }

    get codeComponents() {
        return this.code.split(/:/).slice(1)
    }

    set codeComponents(v) {
        this.code = [this.codePrefix].concat(v).join(":")
    }

    /** @type {string} This is the general product type */
    get codePrefix() {
        throw new Error("Not implemented")
    }

    get forSale() {
        return !this.discontinued
    }

    set forSale(v) {
        this.discontinued = !v
    }

    get isHidden() {
        return this.hidden
    }

    set isHidden(v) {
        console.log('hidden: ' + v);
        this.hidden = v;
    }

    get label() {
        return this._label;
    }

    set label(v) {
        this._label = v;
    }

    get pricing() {
        return this._pricing
    }

    set pricing(v) {
        this._pricing = v
    }

    get productEmailTemplate() {
        return this.emailTemplate;
    }

    set productEmailTemplate(v) {
        this.emailTemplate = v;
    }

    /**
     * The lowest price offered for the standard action, for "from [price] per
     * [period]"
     *
     * @returns {?SBProductPricing}
     */
    get lowestPrice() {
        let prices = null;
        if( this.pricing.find( p => p.action === null && p.currency == (AnyBasket.inst).currency ) ){
            prices = this.pricing.filter( p => p.action === null && p.currency === (AnyBasket.inst).currency )
        } else {
            prices = this.pricing.filter( p => p.action === null && p.currency === null )
        }
        return prices.reduce(
            (carry, p) => {
                if(!carry) {
                    return p
                } else if(this.codePrefix === "domain") {
                    if(p.managedMarkedUpPrice !== null && p.managedMarkedUpPrice < carry.managedMarkedUpPrice) return p
                } else if(p.price !== null && p.price < carry.price) {
                    return p
                } else if( this.code.match(/cloud_server:/) ) {
                    if( typeof CloudProductHelper === 'function' ){
                        var h = CloudProductHelper.inst;
                        var lp = h.lowestPriceSB();
                        return lp ? lp : carry;
                    } else {
                        throw new Error("For lowest price of cloud server products please load the CloudProductHelper class via CloudProductHelper.inst in mounted");
                    }
                } else {
                    return carry
                }
            },
            null
        )
    }

    /** @type {?string} This is the subordinate product type */
    get type() {
        return this.codeComponents[0]
    }

    /**
     * Returns a new basket item for this product
     *
     * @param {?SBProductPricing} pricing The pricing selection
     * @param {?string} name
     * @param {number} multiple
     * @param {?number} quoted_price If supplied, this is pre-multiplied
     * @throws if the pricing isn't on this product
     * @returns {Promise<SBasketItem>}
     */
    async basketItem(
        pricing = null,
        name = null,
        multiple = 1,
        quoted_price = null
    ) {
        if(!pricing) {
            pricing = this.pricing[0]
        }
        if(pricing && pricing.price !== null && this.pricing.some(p => p == pricing)) {
            var vat_rate;
            if(this.taxExempt){
                vat_rate = 0;
            } else {
                vat_rate =
                  await UserCatalogue.inst.preloadSingle("userVATRate")
            }
            const pricingPrice = pricing.managedMarkedUpPrice ? pricing.managedMarkedUpPrice : pricing.price

            return new SBasketItem({
                action: pricing.action,
                contact: null,
                currency: await UserCatalogue.inst.preloadSingle("currency"),
                dependentItems: {},
                name: null,
                paymentRef: null,
                paymentSelections: null,
                paymentType: pricing.paymentType,
                periodMonths: multiple * pricing.periodMonths,
                productCode: this.code,
                productOptionSelections: null,
                quotedPriceFull: Math.round(
                    (quoted_price || (multiple * pricingPrice)) * (100 + +vat_rate)) / 100,
                quotedVAT: Math.round(
                    (quoted_price || (multiple * pricingPrice)) * +vat_rate
                ) / 100,
                service: null,
                serviceConfiguration: null,
                upgradeFromProductCode: null,
            })
        } else {
            throw new Error("Matching pricing not found")
        }
    }

    /**
     *
     * @param {generalPaymentType} general_payment_type
     * @param {Date} expiry_date
     * @returns {number}
     */
    expectedPriceExcludingVATForDate(general_payment_type, expiry_date) {
        const fuzz_date = new Date(expiry_date)
        fuzz_date.setDate(fuzz_date.getDate() - 7)

        const possible_prices = this.pricing.filter(
            p => !p.action && p.paymentType == general_payment_type
        ).sort(
            (a, b) => a.periodMonths - b.periodMonths
        )
        let price = null
        for(const p of possible_prices) {
            const target_date = new Date()
            target_date.setMonth(target_date.getMonth() + p.periodMonths)
            if(target_date >= fuzz_date) {
                price = p
                break
            }
        }
        if(!price) {
            price = possible_prices.pop()
        }

        const base_period_end = new Date()
        base_period_end.setMonth(
            base_period_end.getMonth() + price.periodMonths
        )
        const base_period = base_period_end.valueOf() - new Date().valueOf()

        const target_period = Math.ceil(
            (expiry_date.valueOf() - new Date().valueOf())/86400000
        ) * 86400000

        return Math.round(
            price.price * target_period * 100 / base_period
        ) / 100
    }

    /**
     * Returns the price for the given details
     * @param {SPaymentItem["type"]} payment_type
     * @param {number} months
     * @returns {?number}
     */
    initialPriceFor(payment_type, months) {
        const price_info = this.pricingFor(payment_type, months)

        return price_info && price_info.price
    }

    /**
     * Returns the price for the given details
     * @param {string} general_payment_type
     * @param {number} months
     * @returns {?number}
     */
    initialPriceForGeneral(general_payment_type, months) {
        const price_info = this.pricingForGeneral(general_payment_type, months)

        return price_info && price_info.price
    }

    /**
     *
     * @param {SBProductPricing} price_info
     * @returns {boolean}
     */
    needsPrice(price_info) {
        // Policy: "any" always needed behind any more specific price
        return price_info.paymentType == "any" && this.pricing.some(
            pr => pr.paymentType != "any" && pr.periodMonths == price_info.periodMonths
        )
    }

    /**
     * Returns the price object for the given details
     *
     * @param {SPaymentItem["type"]} payment_type
     * @param {number} months
     * @returns {?SBProductPricing}
     */
    pricingFor(payment_type, months) {
        return this.pricing.find(
            p => (
                p.periodMonths == months &&
                p.matchesPaymentType(payment_type) &&
                p.action === null
            )
        ) || null
    }

    /**
     * Returns the price object for the given details
     *
     * @param {string} general_payment_type
     * @param {number} months
     * @returns {?SBProductPricing}
     */
    pricingForGeneral(general_payment_type, months) {
        return this.pricing.find(
            p => (
                p.periodMonths == months &&
                p.paymentType == general_payment_type &&
                p.action === null
            )
        ) || null
    }

    /**
     * Returns true if the supplied months value is supported
     *
     * @param {number} months
     * @returns {boolean}
     */
    pricingSupportsMonths(months) {
        return this.pricing.some(
            p => (
                p.periodMonths == months &&
                p.action === null
            )
        )
    }

    /**
     * Returns true if the supplied real payment type value is supported
     *
     * @param {SPaymentItem["type"]} payment_type
     * @returns {boolean}
     */
    pricingSupportsRealType(payment_type) {
        return this.pricing.some(
            p => (
                p.matchesPaymentType(payment_type) &&
                p.action === null
            )
        )
    }

    /**
     * Returns a new basket item for this product
     *
     * @param {SBGeneralProduct} from_product
     * @param {SBasketItem["service"]} for_service
     * @param {?SPaymentItem["type"]} real_payment_type
     * @returns {Promise<SBasketItem>}
     */
    async upgradeBasketItem(from_product, for_service, real_payment_type = null) {
        const vat_rate =
            await UserCatalogue.inst.preloadSingle("userVATRate")

        const upgrade_pricing = SBProduct.upgradePricing(
            from_product,
            this,
            real_payment_type,
            new Date(for_service.expiry)
        )

        return new SBasketItem({
            action: null,
            contact: null,
            currency: UserCatalogue.inst.currency,
            dependentItems: {},
            name: null,
            paymentRef: null,
            paymentSelections: null,
            paymentType: upgrade_pricing.generalPaymentType,
            periodMonths: 0,
            productCode: this.code,
            productOptionSelections: null,
            quotedPriceFull: Math.round(
                upgrade_pricing.price * (100 + (+vat_rate))) / 100,
            quotedVAT: Math.round(
                upgrade_pricing.price * (+vat_rate)
            ) / 100,
            service: for_service,
            serviceConfiguration: null,
            upgradeFromProductCode: from_product.code,
        })
    }
    toJSON() {
        return {
            discontinued: this.discontinued,
            hidden: this.hidden,
            forUser: this.forUser,
            label: this.label,
            location: this.location,
            pricing: this.pricing,
            emailTemplate: this.emailTemplate,
            affiliateCommission: this.affiliateCommission,
        }
    }
    toJSONBulk() {
        return {
            code: this.code,
            discontinued: this.discontinued,
            hidden: this.hidden,
            forUser: this.forUser,
            label: this.label,
            location: this.location,
            emailTemplate: this.emailTemplate,
            affiliateCommission: this.affiliateCommission,
            pricing: this.pricing.filter(
                p => p.price !== null
            ),
        }
    }
}

class SBNonTransferProduct extends SBGeneralProduct {
    get defaultAbstractPaymentType() {
        return "any"
    }

    get defaultPricing() {
        return [
            new SBProductPricing({
                action: null,
                isManaged: false,
                managedMarkup: 0,
                managedMarkupType: null,
                managedMarkedUpPrice: null,
                periodMonths: null,
                paymentType: this.defaultAbstractPaymentType,
                price: null
            })
        ]
    }

    get directAddToCartLinks() {
        var out = {};
        for(const p of this.savedPricing){
            if( p.paymentType == "any" ){
                var j = {
                    m: p.periodMonths,
                    c: this.code
                };
                out[p.periodMonths] = btoa(JSON.stringify(j));
            }
        }
        return out;
    }

    get price() {
        const p = this.pricing.find(
            p => !p.action
        )
        return p ? p.price : null
    }

    set price(v) {
        const p = this.pricing.find(
            p => !p.action
        )
        if(p) {
            p.price = v
        } else {
            this.pricing.push(new SBProductPricing({
                action: null,
                isManaged: false,
                periodMonths: 12,
                paymentType: this.defaultAbstractPaymentType,
                price: v
            }))
        }
    }

    get savedPricing() {
        return this.pricing.filter(p => !p.isNew)
    }
}
